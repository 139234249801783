import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { get, post } from "utils/api";
import { showError, showSuccess } from "utils";
import ArrayCodes from "ArrayCodes.json";
import Cookies from "js-cookie";
import { updateThreadDate } from "./threadSlice";
import { setFreeLimitFinished } from "./userSlice";

export const groupMessagesByDate = (messages) => {
  let moment = require("moment");
  const now = moment();
  const groups = {};

  messages.forEach((message, index) => {
    let date = message.created_at == 0 ? now : message.created_at * 1000;
    const momentDate = moment(date);
    const dateKey = momentDate.format("YYYY-MM-DD");

    if (!groups[dateKey]) {
      groups[dateKey] = {
        date: dateKey,
        messages: [message],
      };
    } else {
      const prevMessage = groups[dateKey].messages[groups[dateKey].messages.length - 1];

      if (
        prevMessage &&
        prevMessage.run_id === message.run_id &&
        message.run_id !== "" &&
        prevMessage.run_id !== "" &&
        message.object === "thread.message" &&
        prevMessage.object === "thread.message"
      ) {
        if (prevMessage.content[0] && message.content[0]) {
          prevMessage.prompt += "" + message.prompt;
          prevMessage.content[0].text.value += "" + message.content[0].text.value;
          if (prevMessage.content[0]?.text?.annotations && message.content[0].text.annotations) {
            prevMessage.content[0].text.annotations = prevMessage.content[0].text.annotations.concat(
              message.content[0].text.annotations
            );
          }
        }
      } else {
        groups[dateKey].messages.push(message);
      }
    }
  });

  const groupArray = Object.values(groups);

  return groupArray;
};

export const getImprove = createAsyncThunk("chat/getImprove", async (value, { getState }) => {
  const state = getState();
  const data = {
    thread_id: state.threadsData.activeThreadId,
    text: value,
  };

  const response = await post(`/improve`, data);
  return response;
});

export const sendFeedback = createAsyncThunk("chat/sendFeedback", async (data, { getState }) => {
  const state = getState();
  data.user_id = state.userData.id;
  if (!data.thread_id) {
    data.thread_id = state.threadsData.activeThreadId;
  }
  const response = await post(`/feedback`, data);
  showSuccess("Спасибо за обратную связь");
  return response;
});

export const getMessages = createAsyncThunk("chat/getMessages", async (thread_id) => {
  const response = await get(`/messages`, `thread=${thread_id}`);
  return response;
});

export const sendMessage = createAsyncThunk(
  "chat/sendMessage",
  async ({ thread_id, value }, { dispatch }) => {
    const response = await post(`/message`, {
      threadID: thread_id,
      prompt: value,
    });
    if (response.error || response.code) {
      if (response.code === 23 || response.code === 128 || response.code === 138) {
        setTimeout(() => {
          dispatch(setFreeLimitFinished());
        }, 500);
      }
      throw new Error(response.error);
    }
    dispatch(updateThreadDate({ thread_id }));
    return response;
  }
);

export const runThread = createAsyncThunk("chat/runThread", async ({ thread_id }, { getState, dispatch }) => {
  dispatch(setChatStatus("gettingRole"));
  const accessToken = Cookies.get("analyticsToken");
  const eventSource = new EventSource(
    `${process.env.REACT_APP_BACKEND}/thread/run?thread=${thread_id}&token=${encodeURIComponent(
      accessToken
    )}`,
    { withCredentials: true }
  );

  eventSource.onmessage = (event) => {
    const receivedData = JSON.parse(event.data);
    const state = getState();
    if (state.threadsData.activeThreadId !== thread_id) {
      eventSource.close();
    }
    console.log(receivedData);

    switch (receivedData.event) {
      case "started":
        window.ym(97182671, "reachGoal", "send-request");
        break;
      case "error":
        let result = ArrayCodes.find((item) => item?.code === receivedData?.data?.code);
        dispatch(setChatStatus(""));
        showError(result?.ru ?? receivedData.error ?? "Ошибка при получении данных с сервера");
        eventSource.close();
        break;
      case "role analysis":
        let prompt = receivedData.data.content[0].text.value;

        dispatch(
          addMessage({
            ...receivedData.data,
            object: "system message",
            visibility: true,
            prompt: prompt,
          })
        );
        break;
      case "helper":
        dispatch(setHelper(receivedData.data?.helper));
        break;
      case "run processing":
        dispatch(setChatStatus("gettingMessage"));
        break;
      case "created":
        dispatch(setChatStatus("gettingMessage"));
        break;
      case "message":
        dispatch(removeVisibility());
        dispatch(setChatStatus("newMessage"));
        dispatch(addMessage(receivedData.data));
        break;
      case "completed":
        dispatch(setHelper(""));
        const activeThread = state.threadsData.threadsList?.find(
          (thread) => thread.thread_id === state.threadsData.activeThreadId
        );
        const isSubscriptionActive = state.userData.subscription?.paid;
        if (activeThread?.messages === 3 && !isSubscriptionActive) {
          dispatch(setFreeLimitFinished());
        }
        eventSource.close();
        break;
      default:
        break;
    }
  };

  eventSource.onerror = function (error) {
    eventSource.close();
    showError("Ошибка при получении данных с сервера");
    console.error("EventSource error:", error);
    dispatch(setChatStatus(""));
  };
});

export const chatSlice = createSlice({
  name: "chatData",
  initialState: {
    messages: [],
    status: "",
    tempHelper: "",
    groupedMessages: [],
  },

  reducers: {
    setChatStatus: (state, action) => {
      state.status = action.payload;
    },
    setHelper: (state, action) => {
      state.tempHelper = action.payload;
    },
    clearMessages: (state, action) => {
      state.status = "";
      state.messages = [];
      state.groupedMessages = [];
    },
    addMessage: (state, action) => {
      const isIdNotFound = !state.messages.some((message) => message.id === action.payload.id);

      const isCorrectThread =
        state.messages.length < 1
          ? true
          : state.messages[state.messages.length - 1].thread_id === action.payload.thread_id;

      if ((isIdNotFound || action.payload.role === "system") && isCorrectThread) {
        state.messages.push(action.payload);

        const newMass = structuredClone(current(state));
        state.groupedMessages = groupMessagesByDate(newMass.messages);
      }
    },
    addGuideMessages: (state, action) => {
      state.groupedMessages.push(action.payload);
    },
    removeMessage: (state, action) => {
      state.groupedMessages.pop();
    },
    removeVisibility: (state, action) => {
      const newMass = structuredClone(current(state));
      const messages = newMass.messages;
      let count = 0;
      for (let i = messages.length - 1; i >= 0; i--) {
        if (messages[i].object === "system message") {
          messages[i].visibility = false;
          count++;
          if (count === 2) break;
        }
      }
      state.messages = messages;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMessages.pending, (state, action) => {
      state.messages = [];
      state.status = "getting";
    });
    builder.addCase(getMessages.fulfilled, (state, action) => {
      state.status = "received";
      if (action.payload.data) {
        state.messages = action.payload.data?.reverse();
        state.groupedMessages = groupMessagesByDate(structuredClone(action.payload.data));
      } else {
        state.messages = [];
        state.groupedMessages = [];
      }
    });
    builder.addCase(sendMessage.pending, (state, action) => {
      state.status = "sending";
    });
    builder.addCase(sendMessage.rejected, (state, action) => {
      state.status = "";
      state.messages.pop();
      const newMass = structuredClone(current(state));
      state.groupedMessages = groupMessagesByDate(newMass.messages);
    });
    builder.addCase(sendMessage.fulfilled, (state, action) => {
      state.status = "";
    });
  },
});

export const {
  addMessage,
  setChatStatus,
  clearMessages,
  setHelper,
  removeMessage,
  removeVisibility,
  addGuideMessages,
} = chatSlice.actions;

export default chatSlice.reducer;
